import logo from './logo.svg';
import {HashRouter as Router, Routes, Route} from "react-router-dom";
import './App.css';
import SignIn from './Components/User/SignIn';
import DashBoard from './Components/2023/DashBoard';
import Sidebar from './Components/2023/Sidebar';
import Farmer from './Components/2023/Farmer';
import About from './Components/2023/About';
import ProtectedRoute from './Components/User/ProtectedRoute';
import NavigateDashboard from './Components/2023/NavigateDashboard';
import FarmerProfile from './Components/2023/FarmerProfile';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorPage from './Components/ErrorPage';
import { Box } from '@mui/material';
import TestFile from './Components/User/TestFile';
import Map from './Components/2023/Map';
import SelectYear from './Components/SelectYear/SelectYear';
import OthersDasboard from './Components/Others/OthersDashboard';
import OtherSidebar from './Components/Others/OtherSidebar';
import OthersFarmer from './Components/Others/Farmer';
import OtherAbout from './Components/Others/About';
import OtherFarmerProfile from './Components/Others/FarmerProfile';

function App() {
  return (
    <div className="App">
      <Router>
          <Routes>
              <Route path='/' element={ <NavigateDashboard> <SignIn/></NavigateDashboard>} />
              <Route element={ <ProtectedRoute />}>
                <Route element={<Sidebar /> }>
                  <Route path='/dashboard' element={  <DashBoard/>}  />
                  <Route path='/farmers' element={ <Farmer/> } />
                  <Route path='/profile/:id' element={<FarmerProfile/>} />
                  <Route path='/test-url' element={ <TestFile/> } />
                  <Route path='/about' element={ <About/> } />
                  <Route element={<Box style={{'color': 'lightgrey', borderTop: '1px solid lightgrey'}}>
                    Copyright © 2023 BharatRohan® - Revitalizing agriculture
                  </Box>}/>
                </Route>
                <Route path='/map' element={ <Map/> } />
                <Route element={<OtherSidebar />}>
                  <Route path='/other-dashboard' element={<OthersDasboard />} />
                  <Route path='/other-farmer' element={<OthersFarmer />} />
                  <Route path='/farmer-profile/:id' element={<OtherFarmerProfile />} />
                  <Route path='/other-about' element={<OtherAbout />} />
                  </Route>
                <Route path='/select-year' element={<SelectYear />} />
              </Route>
              <Route path='*' element={<ErrorPage />} />
          </Routes>
        </Router>
        <ToastContainer />
    </div>
  );
}

export default App;
